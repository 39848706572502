<template>
  <div class="actualite">
    <div class="header">
      <section class="hero is-medium">
        <div class="hero-body">
          <div class="">
            <h3 class="title">Les actualités PEF</h3>
          </div>
        </div>
      </section>
    </div>

    <div class="actualite-body">
      <div class="columns is-multiline is-mobile">
        <div
          class="column is-one-quarter-desktop is-half-mobile is-one-quarter-tablet"
          v-for="pef in pefs"
          :key="pef.id"
        >
          <PefComponent :pef="pef" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PefComponent from "@/components/communs/PefComponent.vue";
import axios from "axios";

export default {
  components: { PefComponent },
  data: function () {
    return {
      pefs: [],
    };
  },
  methods: {},
  beforeMount() {
    axios
      .get("public/pefs")
      .then((response) => {
        this.pefs = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.actualite {
  .hero {
    text-align: center;
    h2 {
      margin-left: 0;
    }

    &::before {
      background-image: url("@/assets/hero/actualite.jpg");
    }
    .title {
      color: #ffffff;
    }
  }
  .filtre-part {
    .filtreLabel {
      margin-left: 1%;
    }

    .filtres {
      height: 50px;
      width: 270px;
      border-radius: 15px;
      text-align: center;
      cursor: pointer;
      z-index: 2;
      position: relative;
      margin: 10px auto 0 2%;
      padding: 10px;
      display: flex;
      align-items: center;
      .select-type {
        margin-right: 10px;
      }

      .select {
        color: white;
        select {
          background-color: #e75913;
          color: white;
        }
      }
    }
  }

  .actualite-body {
    margin-top: 10px;
    margin-bottom: 20px;
    margin: 10px 10px 20px 10px;
    .pagination {
      .pagination-list {
        .is-current {
          background-color: #e75913;
          border: #e75913;
        }
      }
    }
  }
}
</style>
