<template>
  <div>
    <div class="pef card">
      <router-link :to="{ name: 'pefDetails', params: { id: pef.id } }">
        <div class="card-content">
          <div class="media">
            <div class="media-content">
              <p class="title is-size-4-desktop is-size-6-mobile">
                {{ pef.title }}
              </p>
              <p
                class="subtitle is-size-6-desktop is-size-7-mobile"
                datetime="2016-1-1"
              >
                {{ dateFormat(pef.date) }}
              </p>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["pef"],
  data() {
    return {
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      isActiveModalepef: false,
    };
  },
  methods: {
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
  },
};
</script>

<style lang="scss">
.pef {
  width: 100%;
  .tag {
    background-color: #e75913;
    color: white;
    //margin-right: 15px;
  }
}
</style>
