<template>
  <div class="pef-detail" v-if="pef != null">
    <div>
      <nav class="breadcrumb" aria-label="breadcrumbs">
        <ul>
          <li><router-link :to="{ name: 'home' }">Accueil</router-link></li>
          <li>
            <router-link :to="{ name: 'pef' }">PEF</router-link>
          </li>
          <li class="is-active">
            <router-link
              :to="{ name: 'pefDetails', params: { id: pef.id } }"
              aria-current="page"
              >{{ pef.title }}</router-link
            >
          </li>
        </ul>
      </nav>
    </div>
    <div class="pef-detail-body">
      <div class="date">{{ dateFormat(pef.date) }} •</div>
      <h4 class="title">{{ pef.title }}</h4>
      <div v-if="pef.pictures.length > 0">
        <div class="picture" v-for="picture in pef.pictures" :key="picture.id">
          <figure class="image">
            <img :src="baseURL + picture.name" alt="image de l'actualité pef" />
          </figure>
        </div>
      </div>

      <p class="text">
        {{ pef.description }}
      </p>
    </div>
    <div class="sharing-parent">
      <div class="separator">Partager cet pef</div>
      <div class="sharing">
        <button class="facebook">
          <ShareNetwork
            network="facebook"
            :url="websiteUrl + pef.id"
            :title="pef.title"
            :description="pef.description"
            hashtags="SCHerbignac,SCH"
          >
            <img src="@/assets/icons/shar-facebook.png" alt="Facebook" />
            <p>Facebook</p>
          </ShareNetwork>
        </button>
        <button class="twitter">
          <ShareNetwork
            network="twitter"
            :url="websiteUrl + pef.id"
            :title="pef.title"
            :description="pef.description"
            hashtags="SCHerbignac,SCH"
          >
            <img src="@/assets/icons/share-twitter.png" alt="Twitter" />
            <p>Twitter</p>
          </ShareNetwork>
        </button>
        <button class="whatsapp">
          <ShareNetwork
            network="whatsapp"
            :url="websiteUrl + pef.id"
            :title="pef.title"
            :description="pef.description"
            hashtags="SCHerbignac,SCH"
          >
            <img src="@/assets/icons/share-whatsapp.png" alt="Whatsapp" />
            <p>Whatsapp</p>
          </ShareNetwork>
        </button>
        <button class="email">
          <ShareNetwork
            network="email"
            :url="websiteUrl + pef.id"
            :title="pef.title"
            :description="pef.description"
            hashtags="SCHerbignac,SCH"
          >
            <img src="@/assets/icons/share-mail.png" alt="Email" />
            <p>Email</p>
          </ShareNetwork>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: ["id"],
  data: function () {
    return {
      pef: null,
      baseURL: "https://api.saintcyrherbignacfootball.fr/",
      websiteUrl: "https://saintcyrherbignacfootball.fr/pef/",
    };
  },
  methods: {
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  beforeMount() {
    axios
      .get("public/pefs/details/" + this.id)
      .then((response) => {
        this.pef = response.data;
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.pef-detail {
  height: 1200%;

  .breadcrumb {
    margin-top: 60px;
    margin-left: 30px;
    a {
      text-decoration: none;
      color: #e75913;
    }
  }

  .pef-detail-body {
    margin-top: 40px;
    text-align: -webkit-center;

    .date {
      text-align: center;
    }

    .title {
      text-align: center;
      margin-bottom: 0.5rem;
      margin-top: 1rem;
    }

    .tags {
      color: #e75913;
      font-weight: bold;
      justify-content: center;
      margin-bottom: 5rem;
      margin-left: 10px;
      div {
        margin-left: 10px;
      }
    }

    .text {
      text-align: center;
      width: 80%;
      white-space: pre-line;
      margin-bottom: 50px;
    }

    .picture {
      padding: 10px;
      margin-top: 20px;
      .image {
        max-width: 60%;
        max-height: 40%;
        margin-top: -30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 20px;
        }
      }
    }
  }

  .sharing-parent {
    .separator {
      display: flex;
      align-items: center;
      text-align: center;
      margin-bottom: 20px;
      font-size: small;
    }

    .separator::before,
    .separator::after {
      content: "";
      flex: 1;
      border-bottom: 1px solid #dfdfdf;
    }

    .separator:not(:empty)::before {
      margin-right: 4em;
    }

    .separator:not(:empty)::after {
      margin-left: 4em;
    }
    .sharing {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 20px;
      font-size: smaller;

      button {
        height: 60px;
        width: 80px;
        border: 0;
        border-radius: 5px;
        p {
          color: #ffffff;
        }
      }

      img {
        height: 35px;
        width: 35px;
      }

      .facebook {
        background-color: #4267b2;
      }

      .twitter {
        background-color: #1da1f2;
      }

      .whatsapp {
        background-color: #25d366;
      }

      .email {
        background-color: #757a8e;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .pef-detail {
    .pef-detail-body {
      .picture {
        .image {
          max-width: 80%;
          max-height: 90%;
          margin-top: -30px;
        }
      }
    }

    .sharing-parent {
      .sharing {
        button {
          height: 40px;
          width: 60px;
          p {
            display: none;
          }
        }

        img {
          max-height: 25px;
          max-width: 25px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .pef-detail {
    .pef-detail-body {
      .picture {
        .image {
          max-width: 90%;
          max-height: 90%;
          margin-top: -30px;
        }
      }
    }

    .sharing-parent {
      .sharing {
        button {
          height: 40px;
          width: 60px;
          p {
            display: none;
          }
        }

        img {
          max-height: 25px;
          max-width: 25px;
        }
      }
    }
  }
}
</style>
