<template>
  <div id="app">
    <nav
      class="navbar is-fixed-top"
      role="navigation"
      aria-label="main navigation"
      itemscope="itemscope"
      itemtype="http://schema.org/SiteNavigationElement"
    >
      <div class="navbar-brand">
        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="true"
          v-on:click="showNav = !showNav"
          v-bind:class="{ 'is-active': showNav }"
        >
          <span aria-hidden="true" style="color: white"></span>
          <span aria-hidden="true" style="color: white"></span>
          <span aria-hidden="true" style="color: white"></span>
        </a>

        <router-link
          class="navbar-item sch-img"
          to="/"
          itemscope
          itemtype="http://schema.org/Brand"
        >
          <img
            src="@/assets/images/SCH.jpg"
            style="height: 80px; border-radius: 3px"
            alt="logo du club de la saint cyr herbignac football"
          />
        </router-link>
      </div>

      <div
        id="client-navbar"
        class="navbar-menu"
        v-bind:class="{ 'is-active': showNav }"
      >
        <div class="navbar-start">
          <router-link
            class="navbar-item"
            to="/"
            v-on:click="showNav = !showNav"
            ><h1>Accueil</h1></router-link
          >
          <router-link
            class="navbar-item"
            to="/organigramme"
            v-on:click="showNav = !showNav"
            ><h2>Organigramme</h2>
          </router-link>

          <router-link
            class="navbar-item"
            to="/partenaire"
            v-on:click="showNav = !showNav"
          >
            <h2>Partenaires</h2>
          </router-link>
          <router-link
            class="navbar-item"
            to="/convocations"
            v-on:click="showNav = !showNav"
          >
            <h2>Convocations</h2>
          </router-link>
          <router-link
            class="navbar-item"
            to="/boutique"
            v-on:click="showNav = !showNav"
          >
            <h2>Boutique</h2>
          </router-link>
          <router-link
            class="navbar-item"
            to="/phototeque"
            v-on:click="showNav = !showNav"
          >
            <h2>Phototeque</h2>
          </router-link>
          <router-link
            class="navbar-item"
            to="/actualite"
            v-on:click="showNav = !showNav"
          >
            <h2>Actualités</h2>
          </router-link>
          <router-link
            class="navbar-item"
            to="/pef"
            v-on:click="showNav = !showNav"
          >
            <h2>Pef</h2>
          </router-link>
          <router-link
            class="navbar-item"
            to="/stage"
            v-on:click="showNav = !showNav"
          >
            <h2>Stage</h2>
          </router-link>
          <!-- <router-link
            class="navbar-item"
            to="/contact"
            v-on:click="showNav = !showNav"
            >Contact</router-link
          > -->
          <div
            class="navbar-item has-dropdown is-hoverable"
            v-if="isAuthenticated()"
          >
            <a class="navbar-item"> <h2>Administration</h2> </a>

            <div class="navbar-dropdown">
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_articles')"
                to="/administration/articles"
              >
                <h3>Articles</h3>
              </router-link>
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_articles')"
                to="/administration/pef"
              >
                <h3>PEF</h3>
              </router-link>
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_convocations')"
                to="/administration/convocations"
              >
                <h3>Convocations</h3>
              </router-link>
              <router-link
                class="navbar-item"
                v-if="checkAbilities('store_games')"
                v-on:click="showNav = !showNav"
                to="/administration/matchs"
              >
                <h3>Matchs</h3>
              </router-link>
              <!-- <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                to="/administration/events"
                >Évènements</router-link
              >
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                to="/administration/planning"
                >Planning</router-link
              >
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                to="/administration/informations"
                >Informations</router-link
              > -->
              <!-- <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                to="/administration/joueurs"
                >Joueurs</router-link
              > -->

              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_users')"
                to="/administration/utilisateurs"
              >
                <h3>Personnes</h3>
              </router-link>

              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_games')"
                to="/administration/teams"
              >
                <h3>Équipes</h3>
              </router-link>
              <!-- <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                to="/administration/boutique"
                >Boutique</router-link
              > -->
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_partenaires')"
                to="/administration/partenaires"
              >
                <h3>Partenaires</h3>
              </router-link>
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_categories')"
                to="/administration/categories"
              >
                <h3>Catégories</h3>
              </router-link>
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_roles')"
                to="/administration/roles"
              >
                <h3>Roles</h3>
              </router-link>
              <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                v-if="checkAbilities('store_tags')"
                to="/administration/tags"
              >
                <h3>Tags</h3>
              </router-link>
              <!-- <router-link
                class="navbar-item"
                v-on:click="showNav = !showNav"
                to="/administration/roles"
                >Role</router-link
              > -->
            </div>
          </div>

          <!-- <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link"> More </a>

            <div class="navbar-dropdown">
              <a class="navbar-item"> About </a>
              <a class="navbar-item"> Jobs </a>
              <a class="navbar-item"> Contact </a>
              <hr class="navbar-divider" />
              <a class="navbar-item"> Report an issue </a>
            </div>
          </div> -->
        </div>

        <div class="navbar-end" v-if="!isAuthenticated()">
          <div class="navbar-item">
            <div class="field is-grouped">
              <p class="control">
                <router-link
                  class="bd-tw-button button"
                  to="/login"
                  v-on:click="showNav = !showNav"
                >
                  <h2>Connexion</h2>
                </router-link>
              </p>
            </div>
          </div>
        </div>
        <div class="navbar-end" v-if="isAuthenticated()">
          <div class="navbar-item">
            <div class="field is-grouped">
              <p class="control">
                <button class="bd-tw-button button" @click="logout">
                  <h2>Déconnexion</h2>
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <router-view />
    <footer
      class="footer"
      itemtype="http://schema.org/WPFooter"
      itemscope="itemscope"
    >
      <div class="columns">
        <div class="column content has-text-centered">
          <img
            src="@/assets/images/SCH.jpg"
            style="height: 70px; border-radius: 3px"
            alt="logo du club"
          />
        </div>
        <div class="column content has-text-centered">
          <div class="social-media content has-text-centered">
            <a
              href="https://www.facebook.com/people/Saint-Cyr-Herbignac-Football/100030899512432/"
              target="_blank"
            >
              <img
                src="@/assets/icons/facebook.png"
                style="height: 40px"
                alt="facebook"
              />
              <p>Facebook</p>
            </a>
            <a
              href="https://www.instagram.com/scherbignac/?igshid=YmMyMTA2M2Y%3D&fbclid=IwAR0O1fe__JHTO3ipm-BdLdu1z7Ry21T3ShHjXPdiyVKrQbYQDHa2RrdDEGM"
              target="_blank"
            >
              <img
                src="@/assets/icons/instagram.png"
                style="height: 40px"
                alt="instagram"
              />
              <p>Instagram</p>
            </a>
          </div>
        </div>
        <div class="column content has-text-centered realisation">
          <div>Saint Cyr Herbignac</div>
          <div>SCH © 2023 tous droits réservés</div>
          <div>Réalisation par Baptiste Boursin</div>
          <a href="/cgu" style="color: #d5d1d1; text-decoration: underline"
            ><div>CGU</div></a
          >
          <a
            href="/mention-legales"
            style="color: #d5d1d1; text-decoration: underline"
            ><div>Mentions légales</div></a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SchApp",
  data() {
    return {
      showNav: false,
      token: null,
      connected: false,
    };
  },
  methods: {
    isAuthenticated() {
      if (this.$store.state.token == "null") {
        this.connected = true;
      }

      if (
        this.$store.state.token != "null" &&
        localStorage.getItem("abilities") != "null"
      ) {
        this.connected = true;
      }
      return this.connected;
    },
    logout() {
      const token = this.$store.state.token;

      const formData = {
        token: token,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        })
        .post("logout", formData)
        .then(
          () => localStorage.setItem("token", "null"),
          localStorage.setItem("abilities", "null"),
          localStorage.setItem("isAuthenticated", false),
          (this.connected = false),
          (this.showNav = false),
          this.$router.push({ name: "home" })
        );
    },
    checkAbilities(ability) {
      if (localStorage.getItem("abilities") != null) {
        if (localStorage.getItem("abilities").includes(ability)) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  beforeCreate() {
    const token = this.$store.state.token;
    let page = this.$route.name;
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .post("public/check")
      .then((response) => {
        if (response.data == "") {
          localStorage.setItem("token", "null");
          localStorage.setItem("abilities", "null");
          localStorage.setItem("isAuthenticated", false);
          if (page == "administration") {
            this.$router.push({ name: "home" });
          }
        }
      });
  },
};
</script>
<style lang="scss">
@import "~bulma";
@font-face {
  font-family: "Mokoto";
  src: local("Mokoto"),
    url("@/font/Mokoto/Mokoto Glitch.ttf") format("truetype");
}

html {
  height: 100%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f0f0;
}

.navbar {
  background-color: #000000;

  .navbar-menu {
    padding: 0;
    .navbar-start {
      .active {
        h2,
        h1,
        h3 {
          color: #e75913;
          background-color: #000000;
        }
        background-color: #000000;
      }

      .navbar-dropdown {
        background-color: #000000;
      }

      .navbar-link:hover {
        background-color: #000000;
        text-decoration: none;
        font-weight: bold;
        color: #ffffff;
      }
      // .navbar-link:active {
      //   background-color: #000000;
      //   text-decoration: none;
      //   font-weight: bold;
      //   color: #e75913;
      // }

      a {
        h2,
        h1,
        h3 {
          font-weight: bold;
          color: #ffffff;
          background-color: #000000;
        }
        background-color: #000000;
      }

      a:hover {
        background-color: #000000;
        text-decoration: none;
      }
    }
  }

  .navbar-end {
    h2,
    h1,
    h3 {
      font-weight: bold;
      color: #000000;
      .navbar-item {
        background-color: #000000;
      }
    }
    background-color: #000000;
  }

  .navbar-burger {
    margin-left: 0;
  }
}

.hero {
  position: relative;
  margin-top: 20px;
  div {
    position: relative;
    z-index: 2;

    p {
      opacity: 100%;
      color: #ffffff;
      margin-left: 200px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      opacity: 100%;
      color: #ffffff;
      margin-left: 200px;
    }
  }

  &::before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.8;
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    background-position: center;
    background-blend-mode: soft-light;
  }
}

.footer {
  background-color: #000000;
  padding: 0rem, 1rem, 1rem;

  .social-media {
    display: flex;
    justify-content: center;
    place-content: space-evenly;
    a {
      text-decoration: none !important;
      color: #e75913;
    }

    a:hover {
      font-weight: bold;
    }
  }

  .realisation {
    color: #d5d1d1;
  }
}

@media screen and (max-width: 400px) {
  .hero {
    position: relative;

    div {
      position: relative;
      z-index: 2;
      margin-top: 20px;

      p {
        opacity: 100%;
        color: #ffffff;
        margin-left: 50px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        opacity: 100%;
        color: #ffffff;
        margin-left: 50px;
      }
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      opacity: 0.8;
      background-repeat: no-repeat;
      background-position: 50% 0;
      background-size: cover;
      background-position: center;
      background-blend-mode: soft-light;
    }
  }

  .sch-img {
    margin-left: 70%;
  }

  .footer {
    background-color: #000000;
    padding: 0rem, 1rem, 1rem;
  }
}
</style>
