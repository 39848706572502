<template>
  <div class="admin-convocation">
    <div class="convocation-header">
      <button class="button is-success" @click="lauchCreate()">
        Ajouter des convocations
      </button>
      <div class="field">
        <label>Seulement match en cours ?</label>
        <input
          type="checkbox"
          checked
          v-model="displayOnlyCurrent"
          @change="filterGames()"
        />
      </div>
    </div>
    <table class="table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Equipe</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="convocation in displayConvocations" :key="convocation.id">
          <td>{{ dateFormat(convocation.date) }}</td>
          <td>{{ convocation.team.name }}</td>
          <td>
            <button
              class="button is-warning update-button"
              @click="lauchUpdate(convocation.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/pen-to-square-solid.svg"
                  alt="icone de crayon"
                />
              </div>
            </button>

            <button
              class="button is-danger"
              @click="lauchDelete(convocation.id)"
            >
              <div class="icon">
                <img
                  src="@/assets/icons/trash-can-solid.svg"
                  alt="icone de poubelle"
                />
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalUpdate }">
      <div class="modal-background" @click="close"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title" v-if="!is_create">
            {{ updateTitle }}
            <br /><br />
            {{ updateDate }}
          </p>
          <p class="modal-card-title" v-if="is_create">
            Ajouter une convocation
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <div class="columns is-centered">
            <div class="column is-12-tablet is-12-desktop is-12-mobile">
              <form @submit.prevent="submitForm" class="box">
                <div class="field no-game-part" v-if="is_create">
                  <label class="label">Pas de match pour une équipe ?</label>
                  <div class="control">
                    <input
                      type="checkbox"
                      v-model="no_game"
                      @change="changeNoGame()"
                    />
                  </div>
                </div>
                <div v-if="no_game">
                  <!-- date, team, category, comment -->

                  <div class="columns">
                    <div class="field column is-5">
                      <label class="label">Date</label>
                      <div class="control">
                        <input
                          class="input"
                          type="date"
                          placeholder="Date"
                          v-model="date"
                          @change="changeDate($event)"
                        />
                      </div>
                    </div>
                    <div class="field column is-7">
                      <label class="label">Sélectionnez une équipe</label>
                      <div class="select">
                        <select v-model="team">
                          <option
                            v-for="team in teams"
                            :key="team.id"
                            :value="team.id"
                          >
                            {{ team.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <textarea
                    class="textarea column is-12"
                    v-model="comment"
                    placeholder="Un petit message ?"
                  ></textarea>
                </div>
                <div v-if="!no_game">
                  <div class="columns">
                    <div class="field column" v-if="is_create">
                      <label class="label">Sélectionnez un match</label>
                      <div class="select">
                        <select @change="changeGame($event)" v-model="gameId">
                          <option
                            v-for="game in games"
                            :key="game.id"
                            :value="game.id"
                          >
                            <div v-if="game.is_home">
                              {{ game.team.name }} - {{ game.opponent }} /
                              {{ dateFormat(game.date) }}
                            </div>
                            <div v-if="!game.is_home">
                              {{ game.opponent }} - {{ game.team.name }} /
                              {{ dateFormat(game.date) }}
                            </div>
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="field column">
                      <label class="label"
                        >Ajouter des joueurs d'une autre catégorie ?</label
                      >
                      <div class="select">
                        <select @change="addCategory($event)">
                          <option
                            v-for="category in categories"
                            :key="category.id"
                            :disabled="
                              alreadyAddCategories.includes(category.id) ||
                              game == null
                            "
                            :value="category.id"
                          >
                            {{ category.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="field no-game-part">
                    <label class="label"
                      >La convocation doit être cachée ?</label
                    >
                    <div class="control">
                      <input type="checkbox" v-model="is_cacher" />
                    </div>
                  </div>
                  <div class="field column">
                    <label class="label">Sélectionnez un responsable</label>
                    <div class="select">
                      <select v-model="responsable_id">
                        <option
                          v-for="responsable in responsables"
                          :key="responsable.id"
                          :value="responsable.id"
                        >
                          {{ responsable.name }} {{ responsable.firstname }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="field column is-4">
                    <label class="label">Heure de Rendez-vous</label>
                    <div class="control">
                      <input class="input" type="time" v-model="appointment" />
                    </div>
                  </div>

                  <div class="field">
                    <label class="label">Description / Message</label>
                    <div class="control">
                      <textarea
                        class="textarea"
                        placeholder="Message"
                        name="texte"
                        v-model="texte"
                      ></textarea>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="field column">
                      <h2 class="subtitle">Sélectionnez les joueurs</h2>
                      <table class="table">
                        <thead>
                          <tr>
                            <th>Nom Prénom</th>
                            <th v-if="category != null && category.has_drivers">
                              <img
                                class="img-table"
                                src="@/assets/icons/car-solid.svg"
                                alt="conducteur"
                              />
                            </th>
                            <th
                              v-if="category != null && category.has_cleaners"
                            >
                              <img
                                class="img-table"
                                src="@/assets/icons/broom-solid.svg"
                                alt="nettoyage"
                              />
                            </th>
                            <th v-if="category != null && category.has_shirts">
                              <img
                                class="img-table"
                                src="@/assets/icons/soccer-jersey-2.png"
                                alt="maillot"
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="player in players"
                            :key="player.id"
                            v-bind:class="{
                              'is-selected': selectedPlayers.includes(
                                player.id
                              ),
                              'is-disabled': alreadyUsedPlayer.includes(
                                player.id
                              ),
                            }"
                          >
                            <td @click="updateSelectedPlayers(player)">
                              {{ player.name }} {{ player.firstname }}
                            </td>
                            <td v-if="category != null && category.has_drivers">
                              <input
                                :disabled="!selectedPlayers.includes(player.id)"
                                type="checkbox"
                                @change="changeCheckDrivers(player, $event)"
                              />
                            </td>
                            <td
                              v-if="category != null && category.has_cleaners"
                            >
                              <input
                                :disabled="!selectedPlayers.includes(player.id)"
                                type="checkbox"
                                @change="changeCheckCleaners(player, $event)"
                              />
                            </td>
                            <td v-if="category != null && category.has_shirts">
                              <input
                                :disabled="!selectedPlayers.includes(player.id)"
                                type="checkbox"
                                @change="changeCheckShirts(player, $event)"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        <div class="notification is-danger is-light" v-if="is_failure">
          {{ error_messsage }}
        </div>
        <footer class="modal-card-foot">
          <div class="columns is-multiline">
            <p class="card-footer-item column is-12" v-if="!no_game">
              <span>
                {{ this.selectedPlayers.length }} joueurs sélectionnés
              </span>
            </p>
            <button
              class="card-footer-item button is-success"
              v-if="is_create"
              @click="add()"
            >
              Confirmer
            </button>
            <button
              class="button is-warning"
              v-if="!is_create"
              @click="update(id)"
            >
              Confirmer les modifications
            </button>

            <button class="button" @click="close">Annuler</button>
          </div>
        </footer>
      </div>
    </div>
    <div class="modal" v-bind:class="{ 'is-active': isActiveModalDelete }">
      <div class="modal-background" @click="closeDelete"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            {{ deleteTile }}
            <br /><br />
            {{ deleteDate }}
          </p>
          <button class="delete" aria-label="close" @click="close"></button>
        </header>
        <section class="modal-card-body">
          <h1>Êtes-vous certains de vouloir supprimer cette convocation ?</h1>
        </section>
        <footer class="modal-card-foot">
          <button class="button" @click="closeDelete">Cancel</button>
          <button
            class="button is-danger"
            v-if="convocationActif != null"
            @click="deleteConvocation(convocationActif)"
          >
            Confirmer suppression
          </button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  components: {},
  data() {
    return {
      games: [],
      convocations: [],
      displayConvocations: [],
      allCurrentConvocations: [],
      currentConvocation: [],
      currConvPlayers: [],
      currConvDrivers: [],
      convocationTitle: null,
      selectedTeam: null,
      matchDate: null,
      matchPlace: null,
      matchRDV: null,
      matchHour: null,
      matchAdversaire: null,
      isHome: true,
      matchType: "bdszhuhsx-dqsd-dsq",
      currConvPlayersCount: null,
      isActiveApercuModal: false,
      isUpdate: false,
      isAdd: false,

      categories: [],
      players: [],
      teams: [],
      responsables: [],

      isActiveModalUpdate: false,
      isActiveModalDelete: false,
      is_create: null,
      id: null,
      appointment: null,
      texte: null,
      game: null,
      date: null,
      category: null,
      team: null,
      responsable_id: null,
      no_game: false,
      is_cacher: false,
      comment: null,
      gameId: null,
      categoryId: null,
      alreadyAddCategories: [],
      alreadyUsedPlayer: [],
      selectedPlayers: [],
      selectedConvocationsPlayer: [],
      error_messsage: null,
      is_failure: false,
      convocationActif: null,
      deleteTile: null,
      deleteDate: null,
      updateTitle: null,
      updateDate: null,
      displayOnlyCurrent: true,
    };
  },
  methods: {
    //commun
    initializeData() {
      (this.isActiveModalUpdate = false),
        (this.isActiveModalDelete = false),
        (this.is_create = null),
        (this.id = null),
        (this.appointment = null),
        (this.texte = null),
        (this.game = null),
        (this.date = null),
        (this.category = null),
        (this.team = null),
        (this.no_game = false),
        (this.gameId = null),
        (this.alreadyAddCategories = []),
        (this.alreadyUsedPlayer = []),
        (this.players = []),
        (this.responsables = []),
        (this.selectedPlayers = []),
        (this.categoryId = null),
        (this.deleteTile = null),
        (this.deleteDate = null),
        (this.updateTitle = null),
        (this.updateDate = null),
        (this.convocationActif = null),
        (this.selectedConvocationsPlayer = []),
        (this.error_messsage = null),
        (this.is_failure = false),
        (this.comment = null),
        (this.is_cacher = false),
        (this.responsable_id = false),
        (this.displayOnlyCurrent = true);
      this.filterGames();
    },
    close() {
      this.initializeData();
    },
    changeNoGame() {
      const checked = this.no_game;
      this.initializeData();
      this.isActiveModalUpdate = true;
      this.is_create = true;
      this.no_game = checked;
    },
    changeGame(event) {
      const id = event.target.value;
      const index = this.games.findIndex((obj) => obj.id == id);
      this.game = this.games[index];
      this.gameId = this.game.id;
      this.team = this.game.team.id;
      this.date = this.game.date;
      this.category = this.game.team.category;
      this.categoryId = this.category.id;
      this.alreadyAddCategories = [];
      this.alreadyAddCategories.push(this.category.id);
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("users/players/" + this.category.id)
        .then((response) => {
          this.players = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("users/coachs/" + this.category.id)
        .then((response) => {
          this.responsables = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("convocations/players/" + this.date)
        .then((response) => {
          this.alreadyUsedPlayer = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeDate(event) {
      this.teams = {};
      this.team = null;
      const date = event.target.value;
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("teams/without/" + date)
        .then((response) => {
          this.teams = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addCategory(event) {
      const id = event.target.value;
      const index = this.categories.findIndex((obj) => obj.id == id);
      const categoryId = this.categories[index].id;
      this.alreadyAddCategories.push(categoryId);
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("users/players/" + categoryId)
        .then((response) => {
          this.players.push(...response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeCheckCleaners(player, event) {
      var isChecked = event.target.checked;
      var object = this.selectedConvocationsPlayer.find(
        (elem) => elem.player == player.id
      );
      const index2 = this.selectedConvocationsPlayer.indexOf(object);
      if (isChecked) {
        this.selectedConvocationsPlayer[index2].is_cleaner = true;
      } else {
        this.selectedConvocationsPlayer[index2].is_cleaner = false;
      }
    },
    changeCheckDrivers(player, event) {
      var isChecked = event.target.checked;
      var object = this.selectedConvocationsPlayer.find(
        (elem) => elem.player == player.id
      );
      const index2 = this.selectedConvocationsPlayer.indexOf(object);
      if (isChecked) {
        this.selectedConvocationsPlayer[index2].is_driver = true;
      } else {
        this.selectedConvocationsPlayer[index2].is_driver = false;
      }
    },
    changeCheckShirts(player, event) {
      var isChecked = event.target.checked;
      var object = this.selectedConvocationsPlayer.find(
        (elem) => elem.player == player.id
      );
      const index2 = this.selectedConvocationsPlayer.indexOf(object);
      if (isChecked) {
        this.selectedConvocationsPlayer[index2].is_shirt = true;
      } else {
        this.selectedConvocationsPlayer[index2].is_shirt = false;
      }
    },
    checkRequired() {
      if (!this.no_game) {
        if (this.game == null) {
          this.is_failure = true;
          this.error_messsage = "Veuillez selectionner un match";
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
          return false;
        }

        if (this.appointment == null) {
          this.is_failure = true;
          this.error_messsage = "Veuillez indiquer une heure de rendez-vous";
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
          return false;
        }
      } else {
        if (this.date == null) {
          this.is_failure = true;
          this.error_messsage = "Veuillez indiquer une date";
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
          return false;
        }

        if (this.team == null) {
          this.is_failure = true;
          this.error_messsage = "Veuillez selectionner une équipe";
          setTimeout(() => {
            this.is_failure = false;
            this.error_messsage = null;
          }, 3000);
          return false;
        }
      }
      return true;
    },
    updateSelectedPlayers(player) {
      if (this.selectedPlayers.includes(player.id)) {
        const index = this.selectedPlayers.indexOf(player.id);
        var object = this.selectedConvocationsPlayer.find(
          (elem) => elem.player == player.id
        );
        const index2 = this.selectedConvocationsPlayer.indexOf(object);
        if (index > -1) {
          this.selectedPlayers.splice(index, 1);
          this.selectedConvocationsPlayer.splice(index2, 1);
        }
      } else {
        this.selectedPlayers.push(player.id);
        this.selectedConvocationsPlayer.push({
          player: player.id,
          is_driver: false,
          is_shirt: false,
          is_cleaner: false,
        });
      }
    },
    dateFormat(date) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      var newDate = new Date(date);
      newDate = newDate.toLocaleDateString("fr-FR", options);
      return newDate;
    },
    //partie ajout
    lauchCreate() {
      this.is_create = true;
      this.isActiveModalUpdate = true;
    },
    add() {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        appointment: this.appointment,
        game: this.gameId,
        date: this.date,
        category: this.categoryId,
        team: this.team,
        no_game: this.no_game,
        comment: this.comment,
        players: this.selectedConvocationsPlayer,
        is_cacher: this.is_cacher,
        responsable_id: this.responsable_id,
        texte: this.texte,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .post("convocations", formData)
        .then((response) => {
          this.convocations.push(response.data);
          const index = this.games.indexOf(this.game);
          this.games.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //partie suppression
    lauchDelete(id) {
      var proxy = { ...this.convocations.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      this.convocationActif = target_copy;
      if (!this.convocationActif.no_game) {
        if (this.convocationActif.game != null) {
          if (this.convocationActif.game.is_home) {
            this.deleteTile =
              this.convocationActif.team.name +
              " - " +
              this.convocationActif.game.opponent;
          } else {
            this.deleteTile =
              this.convocationActif.game.opponent +
              " - " +
              this.convocationActif.team.name;
          }
        } else {
          this.deleteTile = "Suppression de la convocation";
        }
      } else {
        this.deleteTile =
          "Pas de match pour les " + this.convocationActif.team.name;
      }

      this.deleteDate = this.dateFormat(this.convocationActif.date);
      this.isActiveModalDelete = true;
    },
    deleteConvocation(convocation) {
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .delete("convocations/" + convocation.id)
        .then(() => {
          const index = this.convocations.indexOf(convocation);
          this.convocations.splice(index, 1);
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //partie modification
    lauchUpdate(id) {
      var proxy = { ...this.convocations.find((x) => x.id == id) };
      const target_copy = Object.assign({}, proxy);
      if (!target_copy.no_game) {
        if (target_copy.game.is_home) {
          this.updateTitle =
            target_copy.team.name + " - " + target_copy.game.opponent;
        } else {
          this.updateTitle =
            target_copy.game.opponent + " - " + target_copy.team.name;
        }
      } else {
        this.updateTitle = "Pas de match pour les " + target_copy.team.name;
      }
      this.updateDate = this.dateFormat(target_copy.date);
      (this.id = target_copy.id),
        (this.appointment = target_copy.appointment),
        (this.texte = target_copy.texte),
        (this.game = target_copy.game),
        (this.date = target_copy.date),
        (this.category = target_copy.category),
        (this.categoryId = target_copy.category.id),
        (this.team = target_copy.team.id),
        (this.no_game = target_copy.no_game),
        (this.responsable_id = target_copy.responsable_id),
        (this.is_cacher = target_copy.is_cacher == 1 ? true : false),
        (this.gameId = target_copy.game != null ? target_copy.game.id : null),
        (this.comment = target_copy.comment);

      target_copy.convocation_players.forEach((convocation_player) => {
        this.selectedConvocationsPlayer.push({
          player: convocation_player.player.id,
          is_driver: convocation_player.is_driver,
          is_shirt: convocation_player.is_shirt,
          is_cleaner: convocation_player.is_cleaner,
        });
        this.selectedPlayers.push(convocation_player.player.id);
      });

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("users/players/" + this.category.id)
        .then((response) => {
          this.players = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("users/coachs/" + this.category.id)
        .then((response) => {
          this.responsables = response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .get("convocations/players/" + this.date)
        .then((response) => {
          this.alreadyUsedPlayer = response.data;
          this.alreadyUsedPlayer = this.alreadyUsedPlayer.filter(
            (userId) => !this.selectedPlayers.includes(userId)
          );
        })
        .catch((error) => {
          console.log(error);
        });

      this.is_create = false;
      this.isActiveModalUpdate = true;
    },
    update(id) {
      if (!this.checkRequired()) {
        return;
      }
      const formData = {
        appointment: this.appointment,
        game: this.gameId,
        date: this.date,
        category: this.categoryId,
        team: this.team,
        no_game: this.no_game,
        comment: this.comment,
        players: this.selectedConvocationsPlayer,
        is_cacher: this.is_cacher,
        responsable_id: this.responsable_id,
        texte: this.texte,
      };
      axios
        .create({
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .put("convocations/" + id, formData)
        .then((response) => {
          const index = this.convocations.findIndex((obj) => obj.id == id);
          this.convocations[index] = response.data;
          this.initializeData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterGames() {
      if (this.displayOnlyCurrent == true) {
        this.displayConvocations = this.convocations.filter(
          (c) => c.game != null && c.game.is_finish != true
        );
      } else {
        this.displayConvocations = this.convocations;
      }
    },
  },
  beforeMount() {
    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("convocations")
      .then((response) => {
        this.convocations = response.data;
        this.displayConvocations = this.convocations.filter(
          (c) => c.game != null && c.game.is_finish != true
        );
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("games/allNotFinish")
      .then((response) => {
        this.games = response.data;

        var onlygameid = this.convocations.map((conv) =>
          conv.game != null ? conv.game.id : null
        );
        var uniqueids = [...new Set(onlygameid)];
        this.games = this.games.filter((game) => !uniqueids.includes(game.id));
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .create({
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .get("categories")
      .then((response) => {
        this.categories = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>
<style lang="scss">
.admin-convocation {
  .convocation-header {
    display: flex;

    button {
      margin-right: 10px;
    }

    .field {
      display: flex;
      text-align: center;
      flex-direction: column;
    }
  }
  .no-game-part {
    display: flex;
    justify-content: space-between;
  }

  .img-table {
    height: 20px;
    max-width: 100px;
  }

  .is-disabled {
    pointer-events: none;
    background-color: #dddddd;
  }
}
</style>
